<template>
  <div>
    <el-form
      ref="agreementForm"
      :model="agreementInfo"
      :rules="agreementRules"
      label-width="130px"
    >
      <el-form-item
        label="勾选协议："
        prop="agree"
      >
        <el-checkbox
          v-model="agreementInfo.agree"
          :disabled="disableStatus"
        >
          已经阅读房客规则和用户服务协议
        </el-checkbox>
      </el-form-item>
    </el-form>
    <p class="agree-detail">
      <a
        href="https://wsnbh-img.hzanchu.com/acfile/2cca83374bab8b5372ff284559200092.pdf"
        target="_blank"
      >
        《房客协议》
      </a>
    </p>
  </div>
</template>

<script>
import {
  AGREEMENT_RULES
} from './data.js'

export default {
  name: 'AgreementForm',
  props: {
    agreementInfo: {
      type: Object,
      default: function() {
        return {
          agree: false
        }
      }
    },
    disableStatus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      agreementRules: AGREEMENT_RULES
    }
  },
  mounted() {

  },
  methods: {
    // 返回校验结果
    validateForm() {
      let flag = false
      this.$refs['agreementForm'].validate(valid => {
        flag = valid
      })
      return flag
    }
  }
}
</script>
<style scoped>
.agree-detail {
  padding-left: 148px;
}
.agree-detail a {
  color: #409eff;
  font-size: 14px;
}
</style>
