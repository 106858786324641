<template>
  <div class="goods-set-back-form">
    <el-form
      ref="backForm"
      :model="backInfo"
      :rules="backRules"
      label-width="130px"
    >
      <el-form-item
        label="退订设置："
        prop="unsubType"
      >
        <span class="red">适用于店铺所有商品</span>
        <el-radio-group
          v-model="backInfo.unsubType"
          :disabled="disableStatus"
        >
          <el-radio :label="1">
            入住日期前1天（24:00前）退订，可退全款；当日不可退订，取消订单/退订将100%扣除订单金额；
          </el-radio>
          <el-radio :label="2">
            预定后不可退订，取消订单/退订将100%扣除订单金额；
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="友情提示："
        prop="tips"
      >
        <el-input
          v-model="backInfo.tips"
          placeholder="请输入友情提示"
          type="textarea"
          :disabled="disableStatus"
          :rows="2"
          style="max-width: 800px"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  BACK_RULES
} from './data.js'

export default {
  name: 'BackForm',
  props: {
    backInfo: {
      type: Object,
      default: function() {
        return {
          unsubType: '',
          tips: ''
        }
      }
    },
    disableStatus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      backRules: BACK_RULES
    }
  },
  mounted() {

  },
  methods: {
    // 返回校验结果
    validateForm() {
      let flag = false
      this.$refs['backForm'].validate(valid => {
        flag = valid
      })
      return flag
    }
  }
}
</script>
<style>
.goods-set-back-form .el-radio-group{
  display: block;
}
.goods-set-back-form .el-radio {
  margin-bottom: 10px;
  display: block;
}
</style>
<style scoped>
.red {
  color: red;
}
</style>
