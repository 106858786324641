var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goods-set-back-form" },
    [
      _c(
        "el-form",
        {
          ref: "backForm",
          attrs: {
            model: _vm.backInfo,
            rules: _vm.backRules,
            "label-width": "130px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "退订设置：", prop: "unsubType" } },
            [
              _c("span", { staticClass: "red" }, [
                _vm._v("适用于店铺所有商品"),
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.disableStatus },
                  model: {
                    value: _vm.backInfo.unsubType,
                    callback: function ($$v) {
                      _vm.$set(_vm.backInfo, "unsubType", $$v)
                    },
                    expression: "backInfo.unsubType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(
                      " 入住日期前1天（24:00前）退订，可退全款；当日不可退订，取消订单/退订将100%扣除订单金额； "
                    ),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v(
                      " 预定后不可退订，取消订单/退订将100%扣除订单金额； "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "友情提示：", prop: "tips" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "800px" },
                attrs: {
                  placeholder: "请输入友情提示",
                  type: "textarea",
                  disabled: _vm.disableStatus,
                  rows: 2,
                },
                model: {
                  value: _vm.backInfo.tips,
                  callback: function ($$v) {
                    _vm.$set(_vm.backInfo, "tips", $$v)
                  },
                  expression: "backInfo.tips",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }