var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "noticeForm",
          attrs: {
            model: _vm.noticeInfo,
            rules: _vm.noticeRules,
            "label-width": "130px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "入住时间：", prop: "intoTime" } },
            [
              _c("el-time-picker", {
                attrs: {
                  placeholder: "选择时间",
                  disabled: _vm.disableStatus,
                  "value-format": "timestamp",
                },
                model: {
                  value: _vm.noticeInfo.intoTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.noticeInfo, "intoTime", $$v)
                  },
                  expression: "noticeInfo.intoTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "离店时间：", prop: "leaveTime" } },
            [
              _c("el-time-picker", {
                attrs: {
                  placeholder: "选择时间",
                  disabled: _vm.disableStatus,
                  "value-format": "timestamp",
                },
                model: {
                  value: _vm.noticeInfo.leaveTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.noticeInfo, "leaveTime", $$v)
                  },
                  expression: "noticeInfo.leaveTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "入住要求：", prop: "intoRequire" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "800px" },
                attrs: {
                  placeholder: "请输入入住要求",
                  type: "textarea",
                  disabled: _vm.disableStatus,
                  rows: 2,
                },
                model: {
                  value: _vm.noticeInfo.intoRequire,
                  callback: function ($$v) {
                    _vm.$set(_vm.noticeInfo, "intoRequire", $$v)
                  },
                  expression: "noticeInfo.intoRequire",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }