<template>
  <div>
    <div class="goods-data">
      <DetailTitle title="入住须知:" />
      <NoticeForm
        ref="noticeForm"
        :disable-status="disableStatus"
        :notice-info="noticeInfo"
      />
      <DetailTitle title="退改须知:" />
      <BackForm
        ref="backForm"
        :disable-status="disableStatus"
        :back-info="backInfo"
      />
      <DetailTitle title="费用须知:">
        <span style="color: red;font-size: 14px;padding-left: 10px;font-weight: 400;">
          该费用需要用户线下支付与退款，线上订单不统一进行收取
        </span>
      </DetailTitle>
      <CostForm
        ref="costForm"
        :disable-status="disableStatus"
        :cost-info="costInfo"
      />
      <DetailTitle title="入住协议:">
        <span style="color: red;font-size: 14px;padding-left: 10px;font-weight: 400;">
          请认真阅读并确认平台协议
        </span>
      </DetailTitle>
      <AgreementForm
        ref="agreementForm"
        :disable-status="disableStatus"
        :agreement-info="agreementInfo"
      />
      <!--提交按钮-->
      <div class="btn-area">
        <el-button
          type="primary"
          :loading="loading"
          :style="{width: '100px'}"
          @click="submit"
        >
          确 定
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import DetailTitle from '@/components/common/DetailTitle'
import NoticeForm from './noticeForm'
import BackForm from './backForm'
import CostForm from './costForm'
import AgreementForm from './agreementForm'

export default {
  name: 'MessageList',
  components: {
    DetailTitle,
    NoticeForm,
    BackForm,
    CostForm,
    AgreementForm
  },
  data() {
    return {
      disableStatus: false,
      noticeInfo: {
        intoTime: '',
        leaveTime: '',
        intoRequire: ''
      },
      backInfo: {
        unsubType: '',
        tips: ''
      },
      costInfo: {
        feeType: '',
        depositFee: '',
        addMemberFee: '',
        cleanFee: '',
        invoiceType: ''
      },
      agreementInfo: {
        agree: false
      },
      pageId: '', // 空为新增 否则是编辑
      loading: false
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    // 时间格式转换
    changeTime(val) {
      const time0 = (t) => {
        if (t < 10) {
          return `0${t}`
        } else {
          return t
        }
      }
      const defaultDate = new Date()
      const date = new Date(val)
      const year = defaultDate.getFullYear()
      const month = time0(Number(defaultDate.getMonth() + 1))
      const day = time0(defaultDate.getDate())
      const hour = time0(date.getHours())
      const minute = time0(date.getMinutes())
      const second = time0(date.getSeconds())
      const fullTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`
      const resTime = new Date(fullTime).valueOf()
      const result = String(resTime).slice(0, -3)
      return Number(result)
    },
    // 提交
    submit() {
      const that = this
      const noticeValid = that.$refs['noticeForm'].validateForm()
      const costValid = that.$refs['costForm'].validateForm()
      const backValid = that.$refs['backForm'].validateForm()
      const agreeValid = that.$refs['agreementForm'].validateForm()
      if (noticeValid && costValid && backValid && agreeValid && that.agreementInfo.agree) {
        const url = that.$api.storeGoodsSet.add
        const data = {
          into_time: that.changeTime(that.noticeInfo.intoTime),
          leave_time: that.changeTime(that.noticeInfo.leaveTime),
          into_require: that.noticeInfo.intoRequire,
          unsub_type: that.backInfo.unsubType,
          tips: that.backInfo.tips,
          fee_type: that.costInfo.feeType,
          add_member_fee: that.costInfo.addMemberFee,
          clean_fee: that.costInfo.cleanFee,
          invoice_type: that.costInfo.invoiceType,
          agree: that.agreementInfo.agree
        }
        // 编辑时要传id
        if (that.pageId !== '') {
          data.id = that.pageId
        } else {
          delete data.id
        }
        // 有押金时，必填押金金额
        if (Number(that.costInfo.feeType) === 1) {
          data['deposit_fee'] = that.costInfo.depositFee
        } else {
          delete data['deposit_fee']
        }
        that.loading = true
        that.$axios.post(url, data).then(res => {
          const code = _.get(res, 'code', '')
          if (code === 0) {
            that.$message({ type: 'success', message: '设置成功' })
          }
          that.loading = false
        })
        that.getDetail()
      } else {
        that.$message({ type: 'error', message: '请检查必填信息，入住协议请务必勾选' })
      }
    },
    // 获取详情
    getDetail() {
      const that = this
      const url = that.$api.storeGoodsSet.detail
      that.$axios.post(url, {}).then(res => {
        const code = _.get(res, 'code', '')
        const data = _.get(res, 'data', {})
        if (code === 0) {
          if (data.id) {
            that.pageId = data.id
          }
          that.noticeInfo = {
            intoTime: Number(`${data.into_time}000`),
            leaveTime: Number(`${data.leave_time}000`),
            intoRequire: data.into_require
          }
          that.backInfo = {
            unsubType: data.unsub_type,
            tips: data.tips
          }
          that.costInfo = {
            feeType: data.fee_type,
            depositFee: data.deposit_fee || '',
            addMemberFee: data.add_member_fee,
            cleanFee: data.clean_fee,
            invoiceType: data.invoice_type
          }
          that.agreementInfo = {
            agree: true
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.goods-data {
  padding: 20px;
  background: #fff;
}
.btn-area {
  margin-top: 40px;
  padding-left: 50px;
}
</style>
