<template>
  <div class="goods-set-cost-form">
    <el-form
      ref="costForm"
      :model="costInfo"
      :rules="costRules"
      label-width="130px"
    >
      <el-form-item
        label="押金："
        prop="feeType"
      >
        <el-radio-group
          v-model="costInfo.feeType"
          :disabled="disableStatus"
        >
          <el-radio :label="1">
            有押金
          </el-radio>
          <el-radio :label="2">
            无押金
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="costInfo.feeType === 1"
        label="押金金额："
        prop="depositFee"
      >
        <el-input
          v-model="costInfo.depositFee"
          placeholder="请输入押金金额"
          type="number"
          :disabled="disableStatus"
          :style="{'max-width': '300px'}"
        />
      </el-form-item>
      <el-form-item
        label="加客费用："
        prop="addMemberFee"
      >
        <el-input
          v-model="costInfo.addMemberFee"
          placeholder="请输入加客费用"
          :disabled="disableStatus"
          :style="{'max-width': '300px'}"
        />
      </el-form-item>
      <el-form-item
        label="清洁费用："
        prop="cleanFee"
      >
        <el-input
          v-model="costInfo.cleanFee"
          placeholder="请输入清洁费用"
          :disabled="disableStatus"
          :style="{'max-width': '300px'}"
        />
      </el-form-item>
      <el-form-item
        label="开票设置："
        prop="invoiceType"
      >
        <span class="red">适用于店铺所有商品</span>
        <el-radio-group
          v-model="costInfo.invoiceType"
          :disabled="disableStatus"
          :style="{display: 'block'}"
        >
          <el-radio :label="1">
            商家提供发票（离店时找商家开具发票）
          </el-radio>
          <el-radio :label="2">
            商家不提供发票
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  COST_RULES
} from './data.js'

export default {
  name: 'CostForm',
  props: {
    costInfo: {
      type: Object,
      default: function() {
        return {
          feeType: '',
          depositFee: '',
          addMemberFee: '',
          cleanFee: '',
          invoiceType: ''
        }
      }
    },
    disableStatus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      costRules: COST_RULES,
      isShowDepositFee: false
    }
  },
  watch: {
    'costInfo.feeType': 'depositFeeFunc'
  },
  mounted() {

  },
  methods: {
    // 根据押金选项判断押金金额
    depositFeeFunc(val) {
      let costRulesResult = {}
      let isShow = false
      const depositFeeResult = {
        depositFee: [
          { required: true, message: '请输入押金金额', trigger: 'blur' }
        ]
      }
      if (Number(val) === 1) {
        costRulesResult = { ...COST_RULES, ...depositFeeResult }
        isShow = true
      } else {
        costRulesResult = { ...COST_RULES }
        isShow = false
      }
      this.isShowDepositFee = isShow
      this.costRules = costRulesResult
    },
    // 返回校验结果
    validateForm() {
      let flag = false
      this.$refs['costForm'].validate(valid => {
        flag = valid
      })
      return flag
    }
  }
}
</script>
<style scoped>
.red {
  color: red;
}
</style>
