// 入住须知表单校验
const NOTICE_RULES = {
  intoTime: [
    { required: true, message: '请选择入住时间', trigger: 'change' }
  ],
  leaveTime: [
    { required: true, message: '请选择离店时间', trigger: 'change' }
  ],
  intoRequire: [
    { required: true, message: '请输入入住要求', trigger: 'blur' }
  ]
}
// 退改须知表单校验
const BACK_RULES = {
  unsubType: [
    { required: true, message: '请选择退订设置', trigger: 'change' }
  ],
  tips: [
    { required: true, message: '请输入友情提示', trigger: 'blur' }
  ]
}
// 费用须知
const COST_RULES = {
  feeType: [
    { required: true, message: '请选择有无押金', trigger: 'change' }
  ],
  addMemberFee: [
    { required: true, message: '请输入加客费用', trigger: 'blur' }
  ],
  cleanFee: [
    { required: true, message: '请输入清洁费用', trigger: 'blur' }
  ],
  invoiceType: [
    { required: true, message: '请选择开票设置', trigger: 'change' }
  ]
}
// 入住协议
const AGREEMENT_RULES = {
  agree: [
    { required: true, message: '请勾选入住协议', trigger: 'change' }
  ]
}

export {
  NOTICE_RULES,
  BACK_RULES,
  COST_RULES,
  AGREEMENT_RULES
}
