var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goods-set-cost-form" },
    [
      _c(
        "el-form",
        {
          ref: "costForm",
          attrs: {
            model: _vm.costInfo,
            rules: _vm.costRules,
            "label-width": "130px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "押金：", prop: "feeType" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.disableStatus },
                  model: {
                    value: _vm.costInfo.feeType,
                    callback: function ($$v) {
                      _vm.$set(_vm.costInfo, "feeType", $$v)
                    },
                    expression: "costInfo.feeType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v(" 有押金 ")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v(" 无押金 ")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.costInfo.feeType === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "押金金额：", prop: "depositFee" } },
                [
                  _c("el-input", {
                    style: { "max-width": "300px" },
                    attrs: {
                      placeholder: "请输入押金金额",
                      type: "number",
                      disabled: _vm.disableStatus,
                    },
                    model: {
                      value: _vm.costInfo.depositFee,
                      callback: function ($$v) {
                        _vm.$set(_vm.costInfo, "depositFee", $$v)
                      },
                      expression: "costInfo.depositFee",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "加客费用：", prop: "addMemberFee" } },
            [
              _c("el-input", {
                style: { "max-width": "300px" },
                attrs: {
                  placeholder: "请输入加客费用",
                  disabled: _vm.disableStatus,
                },
                model: {
                  value: _vm.costInfo.addMemberFee,
                  callback: function ($$v) {
                    _vm.$set(_vm.costInfo, "addMemberFee", $$v)
                  },
                  expression: "costInfo.addMemberFee",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "清洁费用：", prop: "cleanFee" } },
            [
              _c("el-input", {
                style: { "max-width": "300px" },
                attrs: {
                  placeholder: "请输入清洁费用",
                  disabled: _vm.disableStatus,
                },
                model: {
                  value: _vm.costInfo.cleanFee,
                  callback: function ($$v) {
                    _vm.$set(_vm.costInfo, "cleanFee", $$v)
                  },
                  expression: "costInfo.cleanFee",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开票设置：", prop: "invoiceType" } },
            [
              _c("span", { staticClass: "red" }, [
                _vm._v("适用于店铺所有商品"),
              ]),
              _c(
                "el-radio-group",
                {
                  style: { display: "block" },
                  attrs: { disabled: _vm.disableStatus },
                  model: {
                    value: _vm.costInfo.invoiceType,
                    callback: function ($$v) {
                      _vm.$set(_vm.costInfo, "invoiceType", $$v)
                    },
                    expression: "costInfo.invoiceType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(" 商家提供发票（离店时找商家开具发票） "),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v(" 商家不提供发票 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }