var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "goods-data" },
      [
        _c("DetailTitle", { attrs: { title: "入住须知:" } }),
        _c("NoticeForm", {
          ref: "noticeForm",
          attrs: {
            "disable-status": _vm.disableStatus,
            "notice-info": _vm.noticeInfo,
          },
        }),
        _c("DetailTitle", { attrs: { title: "退改须知:" } }),
        _c("BackForm", {
          ref: "backForm",
          attrs: {
            "disable-status": _vm.disableStatus,
            "back-info": _vm.backInfo,
          },
        }),
        _c("DetailTitle", { attrs: { title: "费用须知:" } }, [
          _c(
            "span",
            {
              staticStyle: {
                color: "red",
                "font-size": "14px",
                "padding-left": "10px",
                "font-weight": "400",
              },
            },
            [_vm._v(" 该费用需要用户线下支付与退款，线上订单不统一进行收取 ")]
          ),
        ]),
        _c("CostForm", {
          ref: "costForm",
          attrs: {
            "disable-status": _vm.disableStatus,
            "cost-info": _vm.costInfo,
          },
        }),
        _c("DetailTitle", { attrs: { title: "入住协议:" } }, [
          _c(
            "span",
            {
              staticStyle: {
                color: "red",
                "font-size": "14px",
                "padding-left": "10px",
                "font-weight": "400",
              },
            },
            [_vm._v(" 请认真阅读并确认平台协议 ")]
          ),
        ]),
        _c("AgreementForm", {
          ref: "agreementForm",
          attrs: {
            "disable-status": _vm.disableStatus,
            "agreement-info": _vm.agreementInfo,
          },
        }),
        _c(
          "div",
          { staticClass: "btn-area" },
          [
            _c(
              "el-button",
              {
                style: { width: "100px" },
                attrs: { type: "primary", loading: _vm.loading },
                on: { click: _vm.submit },
              },
              [_vm._v(" 确 定 ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }