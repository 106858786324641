var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "agreementForm",
          attrs: {
            model: _vm.agreementInfo,
            rules: _vm.agreementRules,
            "label-width": "130px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "勾选协议：", prop: "agree" } },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { disabled: _vm.disableStatus },
                  model: {
                    value: _vm.agreementInfo.agree,
                    callback: function ($$v) {
                      _vm.$set(_vm.agreementInfo, "agree", $$v)
                    },
                    expression: "agreementInfo.agree",
                  },
                },
                [_vm._v(" 已经阅读房客规则和用户服务协议 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "agree-detail" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://wsnbh-img.hzanchu.com/acfile/2cca83374bab8b5372ff284559200092.pdf",
            target: "_blank",
          },
        },
        [_vm._v(" 《房客协议》 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }