<template>
  <div>
    <el-form
      ref="noticeForm"
      :model="noticeInfo"
      :rules="noticeRules"
      label-width="130px"
    >
      <el-form-item
        label="入住时间："
        prop="intoTime"
      >
        <el-time-picker
          v-model="noticeInfo.intoTime"
          placeholder="选择时间"
          :disabled="disableStatus"
          value-format="timestamp"
        />
      </el-form-item>
      <el-form-item
        label="离店时间："
        prop="leaveTime"
      >
        <el-time-picker
          v-model="noticeInfo.leaveTime"
          placeholder="选择时间"
          :disabled="disableStatus"
          value-format="timestamp"
        />
      </el-form-item>
      <el-form-item
        label="入住要求："
        prop="intoRequire"
      >
        <el-input
          v-model="noticeInfo.intoRequire"
          placeholder="请输入入住要求"
          type="textarea"
          :disabled="disableStatus"
          :rows="2"
          style="max-width: 800px"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  NOTICE_RULES
} from './data.js'

export default {
  name: 'NoticeForm',
  props: {
    noticeInfo: {
      type: Object,
      default: function() {
        return {
          intoTime: '',
          leaveTime: '',
          intoRequire: ''
        }
      }
    },
    disableStatus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      noticeRules: NOTICE_RULES
    }
  },
  mounted() {

  },
  methods: {
    // 返回校验结果
    validateForm() {
      let flag = false
      this.$refs['noticeForm'].validate(valid => {
        flag = valid
      })
      return flag
    }
  }
}
</script>
