<template>
  <div class="detail-title">
    {{ title }}
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'DetailTitle',
  props: {
    title: {
      type: String,
      default: 'Title'
    }
  }
}
</script>

<style scoped>
.detail-title{
  font-size:20px;
  font-weight:bolder;
  display:flex;
  align-items: center;
  color:#3c3c3c;
  padding:10px 0 20px 10px;
  margin:20px 0 15px;
}
.detail-title span{
  display: inline-block;
  color:#0066CC
}
</style>
